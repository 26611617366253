@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Segoe UI";
  src: url("./fonts/Segoe\ UI.ttf");
}

@font-face {
  font-family: "Segoe UI Bold";
  src: url("./fonts/Segoe\ UI\ Bold.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}

/* -------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: white;
}
.container {
  width: 80%;
  margin: auto;
}

.activeNav {
  background-color: #f5f5f5;
}
.activeDot {
  display: flex;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
}

.rec.rec-carousel-wrapper .rec-carousel button {
  background: white;
  color: black;
}

.rectangle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#host_screen_scroll::-webkit-scrollbar {
  display: none;
}

.react-international-phone-input{
  width: 100%;
}

a[title]:hover::after {
  content: attr(title);
  width: 200px;
  font-size: 16px;
  background-color: #1F5A96;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  position: absolute;
  right: 0;
  top:40px;
  z-index: 1;
}

.tooltip {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  width: 250px;
  font-size: 16px;
  background-color: #1F5A96;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  right: 0;
  top:40px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}




.categories-menu::-webkit-scrollbar {
  width: 5px;
}

.categories-menu::-webkit-scrollbar-track {
  background-color: #BEBEBE;
}

.categories-menu::-webkit-scrollbar-thumb {
  background-color: #BEBEBE;
  border-radius: 5px;
}

.categories-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 0.5rem;
  overflow-y: auto;
  padding: 10px;
}

/* modal */
.contactUs{
  width: 40%;
  height: fit-content;
}
.terms{
  width: 60%;
  height: 80%;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

/* media query for below tablets */
@media only screen and (max-width: 1024px) {
  .contactUs{
    width: 90%;
  }
  .terms{
    width: 90%;
  }
}



.landing-header{
  background:linear-gradient(180deg, rgba(255, 235, 235, 0.36) 0%, rgba(255, 252, 252, 0.5) 69.79%, rgba(255, 255, 255, 0.81) 100%);
}






.custom-arrow {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  width: 80px;
}

.custom-arrows{
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  position: absolute;
  /* top: 33%; */
  transform: translateY(-100%); 
  width: 80px;

}

.custom-prev {
  left: 10px;
}

.custom-next {
  right: 10px;
}

.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
}
/* Chat Bubble */

.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

/* Chat bubble (the floating button) */
.chat-bubble {
  background: #EF4949;
  color: white;
  padding: 12px 18px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  transition: transform 0.2s ease;
  position: relative;
}

/* Pulse animation for unread messages */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.pulse {
  animation: pulse 1s infinite;
}

/* Notification badge for unread count */
.notification-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  background: red;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  padding: 2px 6px;
}

.chat-bubble:hover {
  transform: scale(1.05);
}

/* Chat window wrapper */
.chat-window {
  width: 400px;
  height: 500px;
  max-width: 90vw;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.15);
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Header area (red) */
.chat-header {
  background: #EF4949;
  color: #fff;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Minimize button */
.minimize-btn {
  margin-left: auto;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 2px 6px;
}
.minimize-btn:hover {
  opacity: 0.8;
}

/* Header info (logo + user name, etc.) */
.header-info {
  display: flex;
  flex-direction: column;
}

/* Larger text for the company name or 'Chat with Us' */
.company-name {
  font-size: 16px;
  font-weight: 600;
}
.guest-name {
  font-size: 13px;
  opacity: 0.9;
}

/* The area that shows messages and input when chat has started */
.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background: #fdfdfd;
}

/* Each chat message bubble container */
.message-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-bubble .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  flex-shrink: 0;
}

.message-content {
  background: #f1f1f1;
  border-radius: 12px;
  padding: 10px 14px;
  max-width: 70%;
  line-height: 1.4;
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
}

/* For the guest’s own messages */
.message-bubble.user {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.message-bubble.user .message-content {
  background: #e7f3ff;
  text-align: right;
}

.message-bubble.user .avatar {
  margin-right: 0;
  margin-left: 8px;
}

/* Input area container */
.chat-input-container {
  padding: 10px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

/* Shared styling for inputs */
.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

/* Button for starting chat or sending messages */
.chat-send-btn {
  width: 100%;
  padding: 10px;
  background: #EF4949;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.chat-send-btn:hover {
  background: #d83b3b;
}

/* Container for the initial screen with the logo at top & inputs at bottom */
.start-chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* space for logo at top, inputs at bottom */
  padding: 20px;
}

/* The logo near the top */
.chat-logo {
  width: 120px;
  margin-top: 30px;
}

/* The bottom area for name/email inputs */
.start-chat-bottom {
  width: 100%;
  margin-bottom: 20px;
}
.message-timestamp {
  font-size: 11px; /* smaller font size */
  color: #888;     /* light gray color */
  margin-top: 4px;
}


/* End of Chat Bubble */

/* Prevent Scroll on Mobile when modals pop up */
body.overflow-hidden {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}


/* .slick-slider button{
  display: none;
} */
.react-datepicker-popper {
  z-index: 9999 !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}